import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { AiFillIdcard, AiFillCreditCard } from 'react-icons/ai';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';

const UploadNidModal = ({ show, handleClose }) => {
  const { queenAuth, setQueenAuth } = useQueenAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nidFront, setNidFront] = useState(null);
  const [nidBack, setNidBack] = useState(null);

  const handleNidUpload = () => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('nid_front', nidFront);
    formData.append('nid_back', nidBack);
    if (nidFront && nidBack) {
      fetch(`${url}/andro/api/admin/queen/upload/nid/${queenAuth.id}`, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setNidFront(null);
            setNidBack(null);
            queenAuth['nid_front'] = data.data.nid_front;
            queenAuth['nid_back'] = data.data.nid_back;
            setQueenAuth(queenAuth);
            alert(data.message);
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
            setError('Something is wrong, Please try again later');
          }
        });
    } else {
      setIsLoading(false);
      setError('Select your NID front and back photo first');
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4> {queenAuth.nid_front ? 'Update NID' : 'Upload NID'}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col className='text-center ' xs={6}>
              {nidFront && (
                <img width={100} src={URL.createObjectURL(nidFront)} alt='' />
              )}
              <br />
              <label htmlFor='nid_front' className='pointer'>
                <AiFillIdcard className='fs-2' />
                <p className='fw-bold'>NID front</p>
                <input
                  className='d-none'
                  onChange={(e) => setNidFront(e.target.files[0])}
                  type='file'
                  id='nid_front'
                  accept='.jpg, .png, .jpeg'
                />
              </label>
            </Col>
            <Col className='text-center' xs={6}>
              {nidBack && (
                <img width={100} src={URL.createObjectURL(nidBack)} alt='' />
              )}
              <br />
              <label htmlFor='nid_back' className='pointer'>
                <AiFillCreditCard className='fs-2' />
                <p className='fw-bold'>NID back</p>
                <input
                  className='d-none'
                  onChange={(e) => setNidBack(e.target.files[0])}
                  type='file'
                  id='nid_back'
                  accept='.jpg, .png, .jpeg'
                />
              </label>
            </Col>
          </Row>
        </div>
        <div className='my-2 text-center'>
          <p className='text-danger'>{error}</p>
        </div>
        <div className='text-center my-3'>
          {isLoading ? (
            <div className='text-center'>
              <button disabled className='next-btn'>
                Please wait..
              </button>
            </div>
          ) : (
            <button onClick={handleNidUpload} className='next-btn'>
              {queenAuth.nid_front ? 'Update NID' : 'Upload NID'}
            </button>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadNidModal;
