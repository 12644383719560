import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../Helpers/Constant';

const ResetPassword = ({ phone, token }) => {
  const [showPass, setShowPass] = useState('password');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newPass, setNewPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');

  const navigate = useNavigate();
  const handleResetPassword = (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (newPass.length > 5 && newPass === repeatPass) {
      fetch(`${url}/api/auth/forget/queen`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          phone,
          token,
          password: newPass,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            alert('Password Changed Successfully');
            navigate('/join');
          } else {
            setIsLoading(false);
            setError('Somethis is wrong, try again after some time.');
          }
        });
    } else {
      setIsLoading(false);
      setError('Password should be same and minimum six length');
    }
  };
  return (
    <div className='pb-3'>
      <h3 className='fw-bold'>Reset Password</h3>
      <form action='' onSubmit={handleResetPassword}>
        <div className='mt-3'>
          <div className='my-2'>
            <input
              placeholder='Enter new Password'
              type={showPass}
              className='sign-up-input w-100'
              onChange={(e) => setNewPass(e.target.value)}
              required
            />
          </div>
          <div className='my-2'>
            <input
              placeholder='Repeat Password'
              type={showPass}
              className='sign-up-input w-100'
              onChange={(e) => setRepeatPass(e.target.value)}
              required
            />
            <div className='mt-3'>
              <input
                type='checkbox'
                onClick={(e) => {
                  if (e.target.checked) {
                    setShowPass('text');
                  } else {
                    setShowPass('password');
                  }
                }}
                id=''
              />{' '}
              <span>Show Password</span>
            </div>
          </div>
          <div>
            <p className='text-center text-danger py-1'>{error}</p>
          </div>
          {isLoading ? (
            <input
              type='submit'
              className='next-btn w-100'
              value='Please wait...'
              disabled
            />
          ) : (
            <input
              type='submit'
              className='next-btn w-100'
              value='Reset Password'
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
