import React, { useState, useEffect } from 'react';
import { url } from '../../Helpers/Constant';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ShowCourse from '../OtwQueenComponents/ShowCourse';

const AllTraining = () => {
  const [allTrainings, setAllTrainings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  useEffect(() => {
    setLoading(true);
    fetch(
      `${url}/otw-training/api/training/get/all/queen-app/by-status/${status}?limit=200&skip=0`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setAllTrainings(data.data);
        setLoading(false);
      });
  }, [status]);
  return (
    <div className='my-5'>
      {loading ? (
        <div className='text-center mt-5'>
          <Spinner animation='border' style={{ color: '#e9313a' }} />
        </div>
      ) : (
        <>
          {allTrainings?.length ? (
            <>
              {allTrainings.map((Training) => (
                <ShowCourse course={Training} key={Training.id} />
              ))}
            </>
          ) : (
            <div className='text-center'>
              <p className='fs-3 font-weight-bold'>
                No more training are available
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllTraining;
