import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ForgotPassword/ResetPassword';
// import Join from './Join';
import Login from './Login/Login';
import OtpSubmit from './Register/OtpSubmit';
import Register from './Register/Register';

const QueenAuth = () => {
  const [joinPhone, setJoinPhone] = useState('');
  const [token, setToken] = useState('');
  return (
    <div className='d-flex flex-md-row flex-column-reverse pt-5 justify-content-between  align-items-center'>
      <Container className='mt-4'>
        <Row>
          <Col className='mx-auto' md={5}>
            <Routes>
              <Route path='/' element={<Login joinPhone={joinPhone} />} />
              <Route
                path='/register'
                element={<Register joinPhone={joinPhone} token={token} />}
              />
              <Route
                path='/register-otp'
                element={
                  <OtpSubmit
                    setToken={setToken}
                    phone={joinPhone}
                    type='register'
                    route='register'
                  />
                }
              />
              <Route
                path='/forget-otp'
                element={
                  <OtpSubmit
                    setToken={setToken}
                    phone={joinPhone}
                    type='forget'
                    route='reset-password'
                  />
                }
              />
              <Route
                path='/forget-password'
                element={
                  <ForgotPassword
                    phone={joinPhone}
                    setJoinPhone={setJoinPhone}
                  />
                }
              />
              <Route
                path='/reset-password'
                element={<ResetPassword phone={joinPhone} token={token} />}
              />
            </Routes>
          </Col>
        </Row>
      </Container>
      <div>
        <img
          className='img-fluid'
          src='/assets/admin-queen/queen_image.png'
          alt=''
        />
      </div>
    </div>
  );
};

export default QueenAuth;
