import React, { useEffect, useState } from 'react';
import { url } from '../../Helpers/Constant';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import './styles.css';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
const QueenSlider = () => {
  const [slider, setSlider] = useState([]);
  useEffect(() => {
    fetch(`${url}/api/content/get/images/app_slider`)
      .then((res) => res.json())
      .then((data) => {
        setSlider(data.data);
      });
  }, []);
  return (
    <div className='mb-1'>
      <div className='px-md-3 px-0'>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className='mySwiper'
        >
          {slider.map((singleSlideImage) => {
            const { id, img } = singleSlideImage;
            // console.log(id);
            return (
              <SwiperSlide key={id}>
                <img
                  loading='lazy'
                  width='100%'
                  src={`${url}/get/image/content_images/${img}`}
                  alt=''
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default QueenSlider;
