import React from 'react';

const MyOffers = () => {
  return (
    <div className='my-5 text-center'>
      <h4>Offers coming soon.!</h4>
    </div>
  );
};

export default MyOffers;
