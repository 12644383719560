import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import handleOtp from '../../../../Hooks/handleOtp';

const ForgotPassword = ({ phone, setJoinPhone }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    handleOtp({
      phone,
      handleOtpRoute: navigate,
      setError,
      type: 'forget',
      route: '/join/forget-otp',
      user: 'queen',
      setIsLoading,
    });
  };
  return (
    <div className='pb-3'>
      <h3 className='fw-bold'>Reset your Password</h3>
      <form action='' onSubmit={handleForgotPassword}>
        <div className='mt-3'>
          <div>
            <input
              placeholder='01XXXXXXXXX'
              type='text'
              className='sign-up-input w-100'
              defaultValue={phone}
              onChange={(e) => setJoinPhone(e.target.value)}
              required
            />
          </div>

          <div className='mt-3'>
            Back to
            <Link to='/join' className='text-decoration-none ps-1'>
              login
            </Link>
          </div>
          <div>
            <p className='text-center text-danger py-2'>{error}</p>
          </div>
          {isLoading ? (
            <div>
              <input
                type='submit'
                className='next-btn mt-2 w-100'
                value='Please wait...'
                disabled
              />
            </div>
          ) : (
            <input
              type='submit'
              className='next-btn mt-2 w-100'
              value='Continue'
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
