import React from 'react';
import { Link } from 'react-router-dom';

const TrainingHome = () => {
  return (
    <div className='my-5 '>
      <div className='text-center my-2 mx-md-5 mx-2 border-bottom border-danger'>
        <Link
          to={`/account/training/all-training?status=Upcoming`}
          className=' text-decoration-none text-black'
        >
          <img
            width={100}
            src='/assets/admin-queen/menu-logos/upcoming.png'
            alt=''
          />

          <p className='fs-5 fw-bold'>Upcoming Training</p>
        </Link>
      </div>

      <div className='text-center my-4 mx-md-5 mx-2 border-bottom border-danger'>
        <Link
          to={`/account/training/all-training?status=Finished`}
          className=' text-decoration-none text-black'
        >
          <img
            width={100}
            src='/assets/admin-queen/menu-logos/finish.png'
            alt=''
          />
          <p className='fs-5 fw-bold'>Finished Training</p>{' '}
        </Link>
      </div>
      <div className='text-center my-2 mx-md-5 mx-2 border-bottom border-danger'>
        <Link
          to={`/account/training/all-training?status=Topic`}
          className=' text-decoration-none text-black'
        >
          <img
            width={100}
            src='/assets/admin-queen/menu-logos/topic.png'
            alt=''
          />
          <p className='fs-5 fw-bold'>Topic Based</p>
        </Link>
      </div>
    </div>
  );
};

export default TrainingHome;
