import React from 'react';

const QueenFreelancing = () => {
  return (
    <div className='my-3'>
      <div className='text-center mb-4'>
        <img
          width={100}
          src='/assets/admin-queen/menu-logos/became-Freelancer.png'
          alt=''
        />
      </div>
      <div className=' text-center '>
        <div>
          <a
            href='https://play.google.com/store/apps/details?id=com.on_the_way.freelancing'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className='training-button'>Download App</button>
          </a>
        </div>

        <div className='mt-5'>
          <a
            href='https://seller.onthe-way.com/login'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className='training-button'>View website</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QueenFreelancing;
