import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../Helpers/Constant';

const OtpSubmit = ({ setToken, phone, type, route }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOtpSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    setError('');
    const otp_creds = {
      phone: Number(phone),
      otp: otp,
      type: type,
    };

    fetch(`${url}/api/otp/match`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ otp_creds: otp_creds }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setToken(data.token);
          setIsLoading(false);
          navigate(`/join/${route}`);
        } else {
          setIsLoading(false);
          setError(data.message);
        }
      });
  };

  return (
    <div className='pb-3'>
      <h3 className='fw-bold '>Enter Otp</h3>
      <form action='' onSubmit={handleOtpSubmit}>
        <div className='mt-3'>
          <div className='my-2'>
            <input
              placeholder='Type your otp here'
              type='number'
              className='sign-up-input w-100'
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>
          <div>
            <p className='text-center text-danger py-2'>{error}</p>
          </div>
          {isLoading ? (
            <input
              type='submit'
              className='next-btn w-100'
              value='Please wait...'
              disabled
            />
          ) : (
            <input type='submit' className='next-btn w-100' value='Submit' />
          )}
        </div>
      </form>
    </div>
  );
};

export default OtpSubmit;
