import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const TermsModal = ({ show, handleClose }) => {
  const [lang, setLang] = useState(true);
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Terms &#38; Conditions</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-end'>
          <button onClick={() => setLang(!lang)}>
            {lang ? 'English' : 'বাংলা'}
          </button>
        </div>
        <div className='p-2'>
          {lang ? (
            <ul className='ps-0'>
              <li>
                আপনার খাদ্য এবং অন্যান্য আইটেমগুলি পরীক্ষা প্যানেল দ্বারা
                পরিদর্শন করা হবে।
              </li>
              <li>
                খাদ্য পরীক্ষার প্রতিক্রিয়া আপনাকে ৩-৪ দিনের মধ্যে সরবরাহ করা হবে
                এবং তারপরে আপনি মেন্যুটি আপলোড করতে পারবেন এবং অ্যাপ্লিকেশনটিতে
                কাস্টমারদের প্রদর্শন করতে পারবেন।
              </li>
              <li>
                আপনার খাবার/অন্যান্য পণ্যের গুণমান এবং স্ট্যান্ডার্ডের জন্য আপনি
                দায়বদ্ধ থাকবেন।
              </li>
              <li>
                আপনার খাবারটি যদি পঁচা,অস্বাস্থ্যকর হয়,গ্রাহকদের অসুস্থ করে তোলে
                তবে আপনি দায়বদ্ধ হবেন।`On The Way' অথবা এর কম্পানি খাবার অথবা
                অন্যান্য পণ্যের গুণমান বা স্ট্যান্ডার্ডের জন্য দায়ী থাকবে না।
              </li>
              <li>
                প্রথম তিনটি অর্ডারের জন্য অ্যাপ চার্জটি ফ্রি।চতুর্থ অর্ডার থেকে
                ২০% (বিশ শতাংশ) অ্যাপ্লিকেশন চার্জ নেওয়া হবে।
              </li>
              <li>
                গ্রাহকরা শুধুমাত্র আপনার নাম জানতে পারবেন,অন্যান্য তথ্য নয়।সমস্ত
                যোগাযোগ অ্যাপ্লিকেশনের মাধ্যমে হবে।
              </li>
              <li>
                প্রতিটি অফার এবং খাবার/অন্য যে কোন পণ্য কর্তৃপক্ষের নিকট
                গ্রহনযোগ্য হতে হবে।
              </li>
              <li>
                আমাদের অ্যাপ্লিকেশন চার্জ ২০% (বিশ শতাংশ) নির্ধারিত। আপনি যদি
                অফার যোগ করেন, এতে অ্যাপ চার্জের কোনো পরিবর্তন হবে না।এটি
                ফিক্সড।
              </li>
            </ul>
          ) : (
            <ul className='ps-0'>
              <li>
                Your food &#38; Product items must be inspected by the testing
                panel.
              </li>
              <li>
                Food test feedback will be provided to you within 3-4 days and
                then you can upload the menu and show to customer in the apps.
              </li>
              <li>
                You will be responsible for the quality and standard of the food
                &#38; Products.
              </li>
              <li>
                You will be responsible if your Products &#38; food is rotten,
                old, bad, unhealthy, causes sickness to consumers. “On the way”
                or its company is not responsible for the quality or standard of
                the food &#38; Products.
              </li>
              <li>
                For the first three orders, the app charge is free. From fourth
                order onwards there will be 20% (Twenty percent) app charge.
              </li>
              <li>
                Customers will only know your name, but not other information.
                All communication will be through app.
              </li>
              <li>
                You have to add minimum 30 (Thirty) members on our facebook page
                &#38; Group.
              </li>
              <li>
                Each and every offer and food &#38; Product will have to be
                accepted by the authority.
              </li>
              <li>
                Our app charge of 20% (Twenty) percent is fixes. If you add
                offer, it will not affect the app charge. It is fixed.
              </li>
            </ul>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;
