import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueenAuth } from "../../Context/QueenAuthContext";
import { url } from "../../Helpers/Constant";

const AddProductModal = ({ show, handleClose, myProduct, setMyProduct }) => {
  const { queenAuth } = useQueenAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [success, setSuccess] = useState(false);

  const dates = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  const handleAddProduct = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("queen_id", queenAuth.id);
    formData.append("product_picture_1", image1);
    formData.append("product_picture_2", image2);

    fetch(`${url}/api/admin/product/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const product = data.data;
        if (data.success) {
          setIsLoading(false);
          setMyProduct([...myProduct, product]);
          setImage1("");
          setImage2("");
          reset();
          setSuccess(true);
        } else {
          setIsLoading(false);
        }
      });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold text-primary'>Add Product</Modal.Title>
      </Modal.Header>
      {success ? (
        <div className='text-center py-3'>
          <h4 className='text-success'>Product Added Successfuly</h4>
          <button
            className='next-btn mt-2 px-2 py-1'
            onClick={() => {
              setSuccess(false);
            }}
          >
            Add More Product
          </button>
        </div>
      ) : (
        // product add modal
        <Modal.Body>
          <form onSubmit={handleSubmit(handleAddProduct)}>
            <div>
              <p className='fw-bold'>Add Product Images:</p>
              <small>
                (you have to add at least one image for each product)
              </small>
            </div>
            <div className='d-flex gap-2 mt-2'>
              {image1 ? (
                <img width={200} src={URL.createObjectURL(image1)} alt='' />
              ) : (
                <input
                  required
                  type='file'
                  onChange={(e) => setImage1(e.target.files[0])}
                />
              )}
              {image2 ? (
                <img width={200} src={URL.createObjectURL(image2)} alt='' />
              ) : (
                <input
                  type='file'
                  onChange={(e) => setImage2(e.target.files[0])}
                />
              )}
            </div>
            <div>
              <div className='mt-3'>
                <p className='fw-bold'>Product Name:</p>
                <input
                  className='sign-up-input w-100'
                  {...register("product_name")}
                  required
                  maxLength='250'
                  type='text'
                  placeholder='Enter product name'
                />
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Product Category:</p>
                <select
                  className='sign-up-input w-50'
                  {...register("category")}
                  name='category'
                  id='category'
                >
                  <option value='Food'>Food</option>
                  <option value='CakeAndPudding'>Cake And Pudding</option>
                  <option value='FishCurry'>Fish Curry</option>
                  <option value='MeatCurry'>Meat Curry</option>
                  <option value='Biriyani'>Biriyani / Rice Items</option>
                  <option value='Dessert'>Dessert</option>
                  <option value='Pitha'>Pitha</option>
                  <option value='VortaVaji'>Vorta Vaji</option>
                  <option value='Pickle'>Pickle</option>
                  <option value='OtherFood'>Other Food</option>
                  <option value='ShareeAndBlouse'>Sharee &amp; Blouse</option>
                  <option value='KurtiAndPants'>
                    Single kurti &amp; Pants
                  </option>
                  <option value='SalwarKameez'>Salwar kameez</option>
                  <option value='LadiesShoes'>Ladies Shoes</option>
                  <option value='Jewellery'>Jewellery</option>
                  <option value='CoupleCollection'>Couple Collection</option>
                  <option value='HijabBorkhaGown'>
                    Hijab Borkha &amp; Gown
                  </option>
                  <option value='FashionAccessories'>
                    Fashion Accessories
                  </option>
                  <option value='Grocery'>Grocery</option>
                  <option value='MensFashion'>Men's Fashion</option>
                  <option value='SkinCare'>Skin Care</option>
                  <option value='HairCare'>Hair Care</option>
                  <option value='MakeupItems'>Makeup Items</option>
                  <option value='BabyProducts'>Baby Products</option>
                  <option value='HomeDecor'>Home Decor</option>
                  <option value='Accessories'>Accessories</option>
                  <option value='Stationary'>Stationary</option>
                  <option value='Toys'>Toys</option>

                  <option value='Others'>Others</option>
                </select>
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Delivery Days:</p>
                <select
                  className='sign-up-input w-25'
                  {...register("delivery_day")}
                  name='delivery_day'
                  id='delivery_day'
                >
                  {dates.map((date) => {
                    return (
                      <option value={date} key={date}>
                        {date}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Product Price:</p>
                <input
                  {...register("price")}
                  type='number'
                  className='sign-up-input '
                  placeholder='Enter product price '
                />
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Weight:</p>
                <input
                  {...register("weight")}
                  max={10}
                  min={0}
                  step='0.1'
                  required
                  type='number'
                  className='w-50 sign-up-input'
                  placeholder='0 to 10kg'
                />
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Enter Product Tags:</p>
                <textarea
                  {...register("tags")}
                  required
                  maxLength='35'
                  rows='2'
                  className='w-100 sign-up-input'
                  placeholder='Enter some tags that related to your product for find this product easily'
                ></textarea>
              </div>
              <div className='mt-3'>
                <p className='fw-bold'>Enter Product Details:</p>
                <textarea
                  {...register("short_desc")}
                  required
                  maxLength='999'
                  rows='4'
                  className='w-100 sign-up-input'
                  placeholder='Enter all the details of your product'
                ></textarea>
              </div>
            </div>
            {isLoading ? (
              <div className='text-center '>
                <Spinner animation='border' style={{ color: "#e9313a" }} />
              </div>
            ) : (
              <div className='text-center'>
                <input
                  className='next-btn '
                  type='submit'
                  value='Add Product'
                />
              </div>
            )}
          </form>
        </Modal.Body>
      )}
      <Modal.Footer className='text-start'>
        <Button
          variant='secondary'
          onClick={() => {
            handleClose();
            setSuccess(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
