import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import TrainingHome from './TrainingHome';
import AllTraining from './AllTraining';

const QueenTrainings = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route
            path='/'
            element={
              <div className='mt-5 text-center '>
                <div className='text-center mb-4'>
                  <img
                    width={100}
                    src='/assets/admin-queen/menu-logos/training.png'
                    alt=''
                  />
                </div>
                <div>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.on_the_way.training'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='training-button'>Download Now</button>
                  </a>
                </div>

                <div className='mt-5'>
                  <Link to='/account/training/home'>
                    <button className='training-button'>View Training</button>
                  </Link>
                </div>
              </div>
            }
          />

          <Route path='/home' element={<TrainingHome />} />
          <Route path='/all-training' element={<AllTraining />} />
        </Routes>
      </div>
    </div>
  );
};

export default QueenTrainings;
