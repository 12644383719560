import React, { useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { url } from '../../Helpers/Constant';

const EditProductModal = ({ show, handleClose, editProduct }) => {
  const dates = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  const {
    category,
    delivery_day,
    id,
    price,
    product_name,
    product_picture_1,
    product_picture_2,
    short_desc,
    tags,
    weight,
  } = editProduct;

  const [isLoading, setIsLoading] = useState(false);
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const { register, handleSubmit, reset } = useForm();

  const handleEditProduct = (data) => {
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    if (image1) {
      formData.append('product_picture_1', image1);
    }
    if (image2) {
      formData.append('product_picture_2', image2);
    }

    fetch(`${url}/api/admin/product/update/queen/${id}`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(
            'Changed product information is in review. It will update after approved by Admin'
          );
          reset();
          setImage1('');
          setImage2('');
          setIsLoading(false);
          handleClose();
        } else {
          alert(data.message)
          setIsLoading(false);
        }
      });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleEditProduct)}>
          <Row>
            <Col xs={6}>
              {image1 ? (
                <img
                  width={100}
                  height={150}
                  style={{ objectFit: 'contain' }}
                  src={URL.createObjectURL(image1)}
                  alt=''
                />
              ) : (
                <img
                  height={150}
                  width={100}
                  style={{ objectFit: 'contain' }}
                  src={`${url}/get/image/products/${product_picture_1}`}
                  alt=''
                />
              )}
              <input
                type='file'
                // className='opacity-0'
                onChange={(e) => setImage1(e.target.files[0])}
              />
            </Col>
            <Col xs={6}>
              {image2 ? (
                <img
                  width={100}
                  height={150}
                  style={{ objectFit: 'contain' }}
                  src={URL.createObjectURL(image2)}
                  alt=''
                />
              ) : (
                <img
                  width={100}
                  style={{ objectFit: 'contain' }}
                  height={150}
                  src={`${url}/get/image/products/${product_picture_2}`}
                  alt=''
                />
              )}
              <input
                type='file'
                onChange={(e) => setImage2(e.target.files[0])}
              />
            </Col>
          </Row>
          <div>
            <div className='mt-3'>
              <p className='fw-bold'>Product Name:</p>
              <input
                className='sign-up-input w-100'
                {...register('product_name')}
                required
                defaultValue={product_name}
                maxLength='250'
                type='text'
                placeholder='Enter product name'
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Product Category:</p>
              <select
                className='sign-up-input w-50'
                {...register('category')}
                defaultValue={category}
                name='category'
                id='category'
              >
                <option value='Food'>Food</option>
                <option value='Shirt'>Shirt</option>
                <option value='Pant'>Pant</option>
                <option value='Panjabi'>Panjabi</option>
                <option value='ShareeAndBlouse'>Sharee &amp; Blouse</option>
                <option value='KurtiAndPants'>Single kurti &amp; Pants</option>
                <option value='SalwarKameez'>Salwar kameez</option>
                <option value='LadiesShoes'>Ladies Shoes</option>
                <option value='Jewellery'>Jewellery</option>
                <option value='CoupleCollection'>Couple Collection</option>
                <option value='HijabBorkhaGown'>Hijab Borkha &amp; Gown</option>
                <option value='FashionAccessories'>Fashion Accessories</option>
                <option value='Grocery'>Grocery</option>
                <option value='MensFashion'>Men's Fashion</option>
                <option value='SkinCare'>Skin Care</option>
                <option value='HairCare'>Hair Care</option>
                <option value='MakeupItems'>Makeup Items</option>
                <option value='BabyProducts'>Baby Products</option>
                <option value='HomeDecor'>Home Decor</option>
                <option value='Accessories'>Accessories</option>
                <option value='Stationary'>Stationary</option>
                <option value='Toys'>Toys</option>
                <option value='Others'>Others</option>
              </select>
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Delivery Days:</p>
              <select
                className='sign-up-input w-25'
                {...register('delivery_day')}
                name='delivery_day'
                id='delivery_day'
                defaultValue={delivery_day}
              >
                {dates.map((date) => {
                  return (
                    <option value={date} key={date}>
                      {date}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Product Price:</p>
              <input
                {...register('price')}
                defaultValue={price}
                type='number'
                className='sign-up-input '
                placeholder='Enter product price '
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Weight:</p>
              <input
                {...register('weight')}
                defaultValue={weight}
                max={10}
                min={0}
                step='0.1'
                required
                type='number'
                className='w-50 sign-up-input'
                placeholder='0 to 10kg'
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Enter Product Tags:</p>
              <textarea
                {...register('tags')}
                defaultValue={tags}
                required
                maxLength='35'
                rows='2'
                className='w-100 sign-up-input'
                placeholder='Enter some tags that related to your product for find this product easily'
              ></textarea>
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Enter Product Details:</p>
              <textarea
                {...register('short_desc')}
                defaultValue={short_desc}
                required
                maxLength='999'
                rows='4'
                className='w-100 sign-up-input'
                placeholder='Enter all the details of your product'
              ></textarea>
            </div>
          </div>
          {isLoading ? (
            <div className='text-center '>
              <Spinner animation='border' style={{ color: '#e9313a' }} />
            </div>
          ) : (
            <div className='text-center'>
              <input
                className='next-btn '
                type='submit'
                value='Update Product'
              />
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;
