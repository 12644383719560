import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, Route, Routes } from 'react-router-dom';
import AddQueenFund from '../AddQueenFund';
import QueenSlider from '../OtwQueenComponents/QueenSlider';
import QueenOrders from './QueenOrders';
import QueenProducts from './QueenProducts';
import QueenProfile from './QueenProfile';
import QueenTrainings from './QueenTrainings';
import BecameFreelancer from '../OtwQueenComponents/BecameFreelancer';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import TranieeModal from '../OtwQueenComponents/TranieeModal';
import MyOffers from './MyOffers';
import QueenBlogs from './QueenBlogs';
import ContactUs from './ContactUs';
import QueenFreelancing from './QueenFreelancing';
import QueenConnect from './QueenConnect';
import QueenTale from './QueenTale';

const QueenAccount = () => {
  const { queenAuth } = useQueenAuth();
  //  be traniee modal
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    document.title = 'Account | Ontheway';
  }, []);

  return (
    <Container>
      <Row>
        <Col className='mx-auto pt-5' md={6}>
          <Routes>
            <Route
              path='/'
              element={
                <div>
                  <div>
                    <QueenSlider />
                  </div>
                  <div>
                    <Row>
                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/profile'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/profile.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>My Profile</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className='text-center mx-md-5 my-2 mx-2'>
                          <Link to='/account/my-products'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/shop.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>My Products</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/manage-orders'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/orders.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>My Orders</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/my-blogs'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/offers.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>My Blogs</p>
                        </div>
                      </Col>

                      <Col xs={6}>
                        {queenAuth.trainee ? (
                          <div className='text-center my-2 mx-md-5 mx-2'>
                            <Link to='/account/training'>
                              <img
                                width={100}
                                src='/assets/admin-queen/menu-logos/training.png'
                                alt=''
                              />
                            </Link>
                            <p className='fs-4 fw-bold'>Training</p>
                          </div>
                        ) : (
                          <div
                            className='text-center my-2 mx-md-5 mx-2'
                            onClick={handleShowModal}
                          >
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/training.png'
                              alt=''
                            />

                            <p className='fs-4 fw-bold'>Be Traniee</p>
                          </div>
                        )}
                      </Col>

                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/freelancing'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/became-Freelancer.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>Freelancing</p>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2 pointer'>
                          <Link to='/account/queen-connect'>
                            <img
                              width={100}
                              height={136}
                              src='/assets/admin-queen/menu-logos/q-connect.png'
                              alt=''
                            />
                          </Link>

                          <p className='fs-4 fw-bold'>Queen Connect</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/queen-tale'>
                            {' '}
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/queenstale.png'
                              alt=''
                            />
                          </Link>

                          <p className='fs-4 fw-bold'>Queens tale</p>
                        </div>
                      </Col>
                      {/* <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/about-us'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/abouts.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>About Us</p>
                        </div>
                      </Col> */}
                      <Col xs={6}>
                        <div className='text-center my-2 mx-md-5 mx-2'>
                          <Link to='/account/contact-us'>
                            <img
                              width={100}
                              src='/assets/admin-queen/menu-logos/message.png'
                              alt=''
                            />
                          </Link>
                          <p className='fs-4 fw-bold'>Contact Us</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <TranieeModal
                    show={showModal}
                    handleClose={handleCloseModal}
                  />
                </div>
              }
            />

            <Route path='/profile' element={<QueenProfile />} />
            <Route path='/my-products' element={<QueenProducts />} />
            <Route path='/manage-orders' element={<QueenOrders />} />
            <Route path='/my-blogs' element={<QueenBlogs />} />
            <Route path='/my-offers' element={<MyOffers />} />
            <Route path='/training/*' element={<QueenTrainings />} />
            <Route path='/became-seller' element={<BecameFreelancer />} />
            <Route path='/add-fund/*' element={<AddQueenFund />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/freelancing' element={<QueenFreelancing />} />
            <Route path='/queen-connect' element={<QueenConnect />} />
            <Route path='/queen-tale' element={<QueenTale />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default QueenAccount;
