import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { RiImageAddLine } from 'react-icons/ri';
import { Link, Route, Routes } from 'react-router-dom';
import { useQueenAuth } from '../Context/QueenAuthContext';
import { url } from '../Helpers/Constant';
import FundFrom from './FundFrom';
import GuaranterFrom from './GuaranterFrom';
import GuaranterNidFrom from './GuaranterNidFrom';
import EditFundModal from './OtwQueenComponents/EditFundModal';

const AddQueenFund = () => {
  const [gId, setGid] = useState('');
  const [fundId, setFundId] = useState('');
  const { queenAuth } = useQueenAuth();
  const [queenFund, setQueenFund] = useState({});
  const [fund, setFund] = useState(false);

  // Edit Profile Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetch(`${url}/api/fund/get/all/by/queen/${queenAuth?.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setQueenFund(data.data);
          setFund(true);
        }
      });
  }, [queenAuth?.id]);

  // Destructors queenFund
  const {
    amount,
    guardian_name,
    guardian_type,
    nid_number,
    return_time,
    return_type,
    status,
    why,
    fund_guaranter,
  } = queenFund;

  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <div>
              {fund ? (
                <div className='mt-4'>
                  <h2 className='my-3'>Queen Fund Details</h2>
                  <div className='d-flex'>
                    <div>
                      <p>
                        <span className='fw-bold'>Queen Name : </span>
                        {queenAuth.name}
                      </p>
                      <p>
                        <span className='fw-bold'>Amount: </span> {amount} tk
                      </p>
                      <p>
                        <span className='fw-bold'>Guardian-Name: </span>
                        {guardian_name}
                      </p>
                      <p>
                        <span className='fw-bold'>Guardian-Type: </span>
                        {guardian_type}
                      </p>
                      <p>
                        <span className='fw-bold'>Nid-Number: </span>{' '}
                        {nid_number}
                      </p>
                      <p>
                        <span className='fw-bold'>Return-Time: </span>
                        {return_time} month
                      </p>
                      <p>
                        <span className='fw-bold'>Return-type: </span>
                        {return_type}
                      </p>
                      <p>
                        <span className='fw-bold'>Status: </span> {status}
                      </p>
                      <p>
                        <span className='fw-bold'>Reason: </span> {why}
                      </p>
                    </div>
                    <div>
                      <Button onClick={handleShow}>Edit</Button>
                    </div>
                  </div>

                  <div className='mt-4'>
                    <h2>Fund Guaranter Details</h2>
                    <div className='mt-3 d-flex'>
                      <div className='mb-3 '>
                        {/* <p className='mb-1'>Guaranter Photo </p> */}
                        <img
                          height={150}
                          src={`${url}/get/image/guaranters/${fund_guaranter?.photo}`}
                          alt=''
                        />
                      </div>
                      <div className='mx-auto'>
                        <p>
                          <span className='fw-bold'> Name : </span>
                          {fund_guaranter.name}
                        </p>
                        <p>
                          <span className='fw-bold'>Address : </span>
                          {fund_guaranter.address}
                        </p>
                        <p>
                          <span className='fw-bold'> Phone : </span>0
                          {fund_guaranter.phone}
                        </p>
                        <p>
                          <span className='fw-bold'> Nid-number : </span>
                          {fund_guaranter.nid_number}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='mt-4'>
                  <Link
                    to='/admin-queen/account/add-fund/fund-from'
                    className='text-decoration-none pointer text-black  '
                  >
                    <div className='text-center pointer opacity-50'>
                      <RiImageAddLine className='add-product' />
                      <p className='fw-bold fs-2 '>Apply For Fund</p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          }
        />
        <Route path='/fund-from' element={<FundFrom setFundId={setFundId} />} />
        <Route
          path='/guaranter'
          element={<GuaranterFrom setGid={setGid} id={fundId} />}
        />
        <Route path='/guaranter-nid' element={<GuaranterNidFrom gId={gId} />} />
      </Routes>

      {fund && (
        <EditFundModal
          show={show}
          handleClose={handleClose}
          queenFund={queenFund}
          // setQueenFund={setQueenFund}
        />
      )}
    </div>
  );
};

export default AddQueenFund;
