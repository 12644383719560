import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';

const EditFundModal = ({ show, handleClose, queenFund }) => {
  const { queenAuth } = useQueenAuth();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [error, setError] = useState('');

  // Destructors queenFund
  const {
    amount,
    guardian_name,
    guardian_type,
    nid_number,
    return_time,
    return_type,
    why,
  } = queenFund;

  const handelFundEdit = (data) => {
    setIsLoading(true);
    setError('');
    data.dob = date;
    data.queen_id = queenAuth.id;
    if (data.nid_number.length > 18) {
      setError('Invalid NID');
    }
    // post from data to server
    fetch(`${url}/api/fund/apply`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // setQueenFund(data);
          alert('Fund Update successfully');
          setError('');
          // setQueenFund(data);
          setIsLoading(false);
          handleClose();
        } else {
          setIsLoading(false);
          setError(data.message);
        }
      });
  };

  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Edit Your Profile</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handelFundEdit)}>
            <div className='mt-2'>
              <input
                className='sign-up-input'
                type='number'
                defaultValue={nid_number}
                {...register('nid_number')}
                required
                placeholder='Enter your nid-number'
              />
              <br />
              <div>
                <label className='mt-3'>Date of birth</label>
                <br />
                <input
                  className='sign-up-input '
                  type='date'
                  onChange={(e) => setDate(e.target.value)}
                  required
                  // {...register('dob', {
                  //   valueAsDate: true,
                  // })}
                />
              </div>

              <div className='d-flex mt-2  align-items-center  '>
                <div>
                  <select
                    className='sign-up-input'
                    required
                    defaultValue={guardian_type}
                    {...register('guardian_type')}
                  >
                    <option value='Father'>Father-name</option>
                    <option value='Husband'>Husband's-name</option>
                  </select>
                </div>
                <div className='mx-3'>
                  <input
                    {...register('guardian_name')}
                    required
                    defaultValue={guardian_name}
                    type='text'
                    className='sign-up-input'
                    placeholder='guardian-name'
                  />
                </div>
              </div>
              <textarea
                style={{ height: '150px' }}
                className='sign-up-input w-100 mt-3'
                required
                minLength={100}
                {...register('why')}
                defaultValue={why}
                placeholder='why you need fund?'
              />
              <input
                className='sign-up-input '
                type='number'
                required
                defaultValue={amount}
                min={1000}
                max={50000}
                {...register('amount')}
                placeholder='Amount 1000-50000 tk'
              />
              <div className='d-flex justify-content-between'>
                <div className='mt-3'>
                  <label> Return Time</label>
                  <div>
                    <select
                      required
                      defaultValue={return_time}
                      className='sign-up-input '
                      {...register('return_time')}
                    >
                      <option value='1'>0-1 m</option>
                      <option value='2'>1-2 m</option>
                      <option value='3'>2-3 m</option>
                      <option value='4'>3-4 m</option>
                      <option value='5'>4-5 m</option>
                      <option value='6'>5-6 m</option>
                    </select>
                  </div>
                </div>
                <div className='my-3'>
                  <label> Return type </label>
                  <div>
                    <select
                      className='sign-up-input '
                      required
                      defaultValue={return_type}
                      {...register('return_type')}
                    >
                      <option value='product'>Product</option>
                      <option value='fund'>Fund</option>
                    </select>
                  </div>
                </div>

                <div className='my-2'>
                  <p> {error} </p>
                </div>
              </div>
              {isLoading ? (
                <div className='d-flex align-items-center'>
                  <input
                    className='next-btn'
                    type='submit'
                    disabled
                    value='Please wait...'
                  />
                </div>
              ) : (
                <input
                  className='next-btn '
                  type='submit'
                  value='Update Fund'
                />
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditFundModal;
