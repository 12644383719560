import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactUsModal from '../OtwQueenComponents/ContactUsModal';
import TermsModal from '../OtwQueenComponents/TermsModal';
import './Footer.css';
const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //  About us modal
  const [aboutModal, setAboutModal] = useState(false);
  const handleCloseAboutModal = () => setAboutModal(false);
  const handleShowAboutModal = () => setAboutModal(true);

  return (
    <div className='pt-4 footer-section '>
      <Container>
        <div>
          <Row className='d-flex'>
            <Col md={6} className='text-md-start text-center'>
              <Row className='d-md-flex'>
                <Col md={12} lg={4}>
                  <img
                    className='img-fluid'
                    loading='lazy'
                    width='170px'
                    src='/assets/main-logo.png'
                    alt=''
                  />
                  <h5 className='fw-bold mt-2 text-black footer-text'>
                    নারী হোক উদ্যোক্তা
                  </h5>
                </Col>
                <Col md={12} lg={8}>
                  <div className=' text-black d-md-flex gap-2 justify-content-evenly py-3 mt-3 footer-box px-1 '>
                    <div>
                      <h6>Email</h6>
                      <small>onthewayqueen@gmail.com</small>
                    </div>
                    <div>
                      <h6>Call</h6>
                      <div className=' flex-column d-sm-flex'>
                        <small>+8809638336677 </small>
                        <small>+8801894829225</small>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div className='mt-3 mt-md-0'>
                <Row>
                  <Col>
                    <h5 className='text-black  footer-category'>
                      Related Link
                    </h5>
                    <ul className='footer-list'>
                      <li>
                        <Link to='/account/profile'>My Profile</Link>
                      </li>
                      <li>
                        <a
                          href='https://onthe-way.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Visit website
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://play.google.com/store/apps/details?id=com.m360ict.ontheway_queen'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Download Queen App
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://freelancing.onthe-way.com'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          freelancing
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <h5 className='text-black footer-category'>About us</h5>
                    <ul className='footer-list'>
                      <li>
                        <Link
                          onClick={handleShowAboutModal}
                          to='#'
                          className='
                      text-decoration-none  '
                        >
                          Contact us
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='#'
                          className='
                      text-decoration-none  '
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={handleShow}
                          to='#'
                          className='
                      text-decoration-none  '
                        >
                          Terms &#38; Conditions
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <h5 className='text-black footer-category'>FollowUs</h5>
                    <ul className='footer-list'>
                      <li>
                        <a
                          href='https://www.facebook.com/ontheway360/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.instagram.com/onthewayqueen/'
                          rel='noreferrer'
                          target='_blank'
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.youtube.com/channel/UC7XneMh4nRLEvVbQaPCUJKA'
                          rel='noreferrer'
                          target='_blank'
                        >
                          Youtube
                        </a>
                      </li>

                      <li>
                        <a
                          href='https://www.linkedin.com/company/on-the-way-queen'
                          rel='noreferrer'
                          target='_blank'
                        >
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://twitter.com/OnTheWa92679397'
                          rel='noreferrer'
                          target='_blank'
                        >
                          Twitter
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={4}>
            <div className='address-style'>
              <div className='address-inner'>
                <h4>Banani Office :</h4>
                <p>
                  House: 74, Road: 7, Block H, Banani, Dhaka – 1213, Bangladesh
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className='address-style'>
              <div className='address-inner'>
                <h4>Agargaon Office :</h4>

                <p>
                  E-14/X, ICT Tower(14th Floor), Agargaon, Sher-E-Bangla Nagar,
                  Dhaka-1207, Bangladesh
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className='address-style'>
              <div className='address-inner'>
                <h4>Chattogram Office :</h4>

                <p>
                  Press Club Bhaban(6th Floor), Jamal Khan Road, Chittagong,
                  Bangladesh
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className='d-md-flex justify-content-between align-items-center pb-5 pb-md-0 footer-bar mb-4 mt-3'>
          <div className='text-black mt-3 d-flex justify-content-between'>
            <div>
              <small>
                &copy; {new Date().getFullYear()} On The Way. All Rights
                Reserved
              </small>
            </div>
          </div>
        </div>
      </Container>

      <TermsModal show={show} handleClose={handleClose} />
      <ContactUsModal show={aboutModal} handleClose={handleCloseAboutModal} />
    </div>
  );
};

export default Footer;
