import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useQueenAuth } from '../../../../Context/QueenAuthContext';
import { url } from '../../../../Helpers/Constant';

import { districts } from '../../../../fakeData/districts';

// import { useQueenAuth } from '../../../../../contexts/QueenAuthContext';
// import { url } from '../../../../../Helpers/Constant';

const Register = ({ joinPhone, token }) => {
  const { register, handleSubmit } = useForm();
  const [reference, setReference] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const { setQueenAuth } = useQueenAuth();
  const navigate = useNavigate();
  const [divisionID, setDivisionID] = useState('');
  const [ref, setRef] = useState(false);
  const [email, setEmail] = useState('');

  const handleQueenRegister = (data) => {
    console.log(data);
    setIsLoading(true);
    if (reference) {
      data.reference_id = `${reference}`;
    }

    if (email) {
      data.email = email;
    }
    const formData = new FormData();
    if (data.phone.length === 11 && data.phone.startsWith('01')) {
      if (data.password.length > 5) {
        if (data.password === repeatPass) {
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
          formData.append('token', token);
          formData.append('photo', image);

          fetch(`${url}/andro/api/auth/queen/register`, {
            method: 'POST',
            body: formData,
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                setQueenAuth(data.data);
                setIsLoading(false);
                navigate('/account');
              } else {
                setIsLoading(false);
                setError(data.message);
                setIsLoading(false);
                setTimeout(() => {
                  setError('');
                }, 4000);
              }
            });
        } else {
          setError('password does not match');
          setIsLoading(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        }
      } else {
        setError('password must be at least 6 characters');
        setIsLoading(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } else {
      setError('Invalid phone number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  return (
    <div className='pb-4'>
      <h3 className='fw-bold'>Queen Registration</h3>
      <form onSubmit={handleSubmit(handleQueenRegister)}>
        <div className='mt-3'>
          <div className='my-2 fw-bold'>
            <label className='mb-1'>Enter your full name</label>
            <input
              placeholder='Full Name'
              type='text'
              className='sign-up-input w-100'
              {...register('name', { required: true })}
              required
            />
          </div>
          <div className='my-2'>
            <label className='mb-1 fw-bold'>Enter your phone number</label>
            <input
              placeholder='01XXXXXXXXX'
              type='number'
              className='sign-up-input w-100'
              defaultValue={joinPhone}
              {...register('phone', { required: true, maxLength: 11 })}
              required
            />
          </div>
          <div className='my-2'>
            <label className='mb-1 fw-bold'>Enter your email</label>
            <input
              placeholder='Enter Your Email'
              type='email'
              className='sign-up-input w-100'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='my-2'>
            <div>
              <div className='d-flex gap-5 align-items-center'>
                <div>
                  <label className='fw-bold text-start pb-1'>
                    Select your division
                  </label>
                  <select
                    onClick={(e) => setDivisionID(e.target.value)}
                    className='sign-up-input w-100'
                    required
                    {...register('division')}
                  >
                    <option value=''>Select Division</option>
                    <option value='Barisal'>Barisal</option>
                    <option value='Chittagong'>Chittagong</option>
                    <option value='Dhaka'>Dhaka</option>
                    <option value='Khulna'>Khulna</option>
                    <option value='Mymensingh'>Mymensingh</option>
                    <option value='Rajshahi'>Rajshahi</option>
                    <option value='Rangpur'>Rangpur</option>
                    <option value='Rajshahi'>Sylhet</option>
                  </select>
                </div>
                <div>
                  {divisionID && (
                    <div>
                      <label className='fw-bold text-start pb-1'>
                        Select your city
                      </label>

                      <select
                        className='sign-up-input w-100'
                        required
                        {...register('city')}
                      >
                        {districts.map(
                          (dis) =>
                            divisionID === dis.division_name && (
                              <option value={dis.name} key={dis.id}>
                                {dis.name}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className='my-2'>
                <label className='mb-1 fw-bold'>Enter your address</label>
                <br />
                <input
                  placeholder='Enter Your Address'
                  type='text'
                  required
                  className='sign-up-input w-100'
                  {...register('address')}
                />
              </div>
            </div>
          </div>

          <div className='my-2'>
            <label className='mb-1 fw-bold'>Select photo</label>
            <input
              type='file'
              className='sign-up-input w-100'
              accept='.jpg, .png, .jpeg'
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* afanfa */}
          <div className='my-2'>
            <label className='mb-1 fw-bold'>Enter password</label>
            <input
              placeholder='Enter Password'
              className='sign-up-input w-100'
              {...register('password', { required: true, minLength: 6 })}
              required
            />
            <br />
            <label className=' mt-1 fw-bold '>Retype password</label>
            <input
              placeholder='Repeat Password'
              className='sign-up-input w-100 my-2'
              onChange={(e) => setRepeatPass(e.target.value)}
              required
            />

            {ref && (
              <div className='my-2 fw-bold'>
                <label className='mb-1'>Reference id(Optional)</label>
                <input
                  placeholder='Reference Id (OTW-***)'
                  type='number'
                  className='sign-up-input w-100'
                  onChange={(e) => setReference(e.target.value)}
                />
              </div>
            )}

            <div className='mt-3 d-flex justify-content-between'>
              <div>
                <input
                  type='checkbox'
                  onClick={(e) => {
                    if (e.target.checked) {
                      setRef(true);
                    } else {
                      setRef(false);
                    }
                  }}
                  id=''
                />{' '}
                <span>Add reference</span>
              </div>
              <div>
                <Link className=' text-decoration-none' to='/join'>
                  Already Have Account?
                </Link>
              </div>
            </div>
          </div>

          {error && (
            <div className='text-center my-2'>
              <p className='fw-bold text-danger'>{error}</p>
            </div>
          )}

          {isLoading ? (
            <input
              type='submit'
              className='next-btn w-100'
              value='Please wait...'
              disabled
            />
          ) : (
            <input type='submit' className='next-btn w-100' value='Register' />
          )}
        </div>
      </form>
    </div>
  );
};

export default Register;
