import React from 'react';

const QueenBlogs = () => {
  return (
    <div className='my-5 text-center'>
      <p className='fs-3 font-weight-bold'>Blog Feature is Upcoming</p>
    </div>
  );
};

export default QueenBlogs;
