import { Navigate, useLocation } from 'react-router-dom';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import ShowSpinner from '../OtwQueenComponents/ShowSpinner/ShowSpinner';
const PrivateRoute = ({ children }) => {
  const { queenAuth, isLoading } = useQueenAuth();
  let location = useLocation();
  if (isLoading) {
    return <ShowSpinner />;
  } else {
    return queenAuth.phone ? (
      children
    ) : (
      <Navigate to='/join' state={{ from: location }} replace />
    );
  }
};

export default PrivateRoute;
