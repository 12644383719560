import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';

const ChangePasswordModal = ({ show, handleClose }) => {
  const { queenAuth } = useQueenAuth();
  const { register, handleSubmit, reset } = useForm();
  const [error, setError] = useState('');
  const [showPass, setShowPass] = useState('password');
  const [repeatPass, setRepeatPass] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = (data) => {
    setIsLoading(true);
    setError('');
    if (data.new_password === repeatPass && repeatPass.length > 5) {
      fetch(`${url}/andro/api/admin/queen/update/password/${queenAuth.id}`, {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            alert(data.message);
            setIsLoading(false);
            reset();
            handleClose();
          } else {
            setError('Old ' + data.message);
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
      setError('Password must be same and lenth should be six');
    }
  };
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Change Your Password</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <div>
            <div>
              <p className='fw-bold'>Enter Old Password:</p>
              <input
                className='sign-up-input w-100'
                {...register('old_password')}
                required
                maxLength='250'
                type={showPass}
                placeholder='Old password'
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Set New Password:</p>
              <input
                {...register('new_password')}
                type={showPass}
                className='sign-up-input w-100'
                placeholder='New password'
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Repeat New Password:</p>
              <input
                onChange={(e) => setRepeatPass(e.target.value)}
                required
                type={showPass}
                className='w-100 sign-up-input'
                placeholder='Repeat new password'
              />
            </div>
            <div className='mt-3'>
              <input
                type='checkbox'
                onClick={(e) => {
                  if (e.target.checked) {
                    setShowPass('text');
                  } else {
                    setShowPass('password');
                  }
                }}
                id=''
              />{' '}
              <span>Show Password</span>
            </div>
            <div>
              <p className='text-center text-danger py-1'>{error}</p>
            </div>
          </div>
          <div className='mt-2'>
            {isLoading ? (
              <div className='d-flex align-items-center'>
                <input
                  className='next-btn'
                  type='submit'
                  disabled
                  value='Please wait...'
                />
              </div>
            ) : (
              <input
                className='next-btn '
                type='submit'
                value='Change Password'
              />
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
