import { createContext, useContext, useEffect, useState } from 'react';

const QueenAuthContext = createContext();

const QueenAuthContextProvidor = ({ children }) => {
  const [queenAuth, setQueenAuth] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const setQueenAuthData = (data) => {
    sessionStorage.setItem('__q_o', JSON.stringify(data));
    setQueenAuth(data);
  };

  useEffect(() => {
    const queen = sessionStorage.getItem('__q_o');

    if (queen) {
      const nowQueen = JSON.parse(queen);
      setQueenAuth(nowQueen);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [queenAuth.phone]);

  return (
    <QueenAuthContext.Provider
      value={{
        queenAuth,
        setQueenAuth: setQueenAuthData,
        isLoading,
      }}
    >
      {children}
    </QueenAuthContext.Provider>
  );
};

const useQueenAuth = () => {
  return useContext(QueenAuthContext);
};

export { useQueenAuth, QueenAuthContextProvidor };
