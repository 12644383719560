import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { url } from '../Helpers/Constant';

const GuaranterFrom = ({ id, setGid }) => {
  const { register, handleSubmit } = useForm();
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const nevigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError('');
    data.dob = data.dob.toISOString().split('T')[0];
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append('photo', image);

    if (data.nid_number.length > 18) {
      setError('Invalid NID');
    }

    fetch(`${url}/api/fund/update/guaranter/${id}`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setGid(data.g_id);
          nevigate('/admin-queen/account/add-fund/guaranter-nid');
          setIsLoading(false);
        } else {
          setError(data.message);
          setIsLoading(false);
        }
      });
  };

  return (
    <div className='my-5'>
      <div>
        <h3 className='my-3 text-center bold'>Guaranter Deatils from</h3>
        <form onSubmit={handleSubmit(onSubmit)} className='fund'>
          <input
            className='sign-up-input '
            required
            type='text'
            {...register('name')}
            placeholder='Enter guaranter name'
          />
          <input
            className='sign-up-input '
            {...register('address')}
            required
            placeholder='Enter guaranter address'
          />
          <input
            className='sign-up-input '
            type='number'
            required
            {...register('nid_number')}
            placeholder='Enter guaranter nid-number'
          />
          <label className='mt-3'>Relation with Guaranter</label>
          <br />
          <select className='sign-up-input' required {...register('relation')}>
            <option value='Father'>Father</option>
            <option value='Mother'>Mother</option>
            <option value='Husband'>Husband</option>
            <option value='Brother'>Brother</option>
            <option value='Sister'>Sister</option>
          </select>
          <br />
          <label className='mt-3'>Guaranter Photo</label>
          <input
            className='sign-up-input'
            required
            // {...register('photo')}
            onChange={(e) => setImage(e.target.files[0])}
            type='file'
          />

          <label className='mt-3'>Date of birth</label>
          <input
            className='sign-up-input '
            type='date'
            required
            {...register('dob', {
              valueAsDate: true,
            })}
          />
          <input
            className='sign-up-input '
            type='number'
            required
            maxLength={11}
            {...register('phone')}
            placeholder='Enter guaranter phone-number'
          />
          <p className='text-danger text-center'>{error}</p>
          {isLoading ? (
            <input
              type='submit'
              className='sign-up-input '
              value='Please wait...'
              disabled
            />
          ) : (
            <input
              className='sign-up-input mt-2'
              type='submit'
              value='Submit'
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default GuaranterFrom;
