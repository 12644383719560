import React, { useEffect, useState } from 'react';
import { RiHome4Line, RiImageAddLine, RiUser6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import ShowMyProduct from '../OtwQueenComponents/ShowMyProduct';
import AddProductModal from '../OtwQueenComponents/AddProductModal';
import EditProductModal from '../OtwQueenComponents/EditProductModal';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';
import { Spinner } from 'react-bootstrap';

const QueenProducts = () => {
  const [myProduct, setMyProduct] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const { queenAuth } = useQueenAuth();

  //Add Product Modal
  const [showProductAdd, setShowProductAdd] = useState(false);
  const handleProductAddClose = () => setShowProductAdd(false);
  const handleProductAddShow = () => setShowProductAdd(true);

  //Edit Product Modal
  const [showProductEdit, setShowProductEdit] = useState(false);
  const handleProductEditClose = () => setShowProductEdit(false);
  const handleProductEditShow = () => setShowProductEdit(true);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${url}/andro/api/admin/product/get/all/by-queen/for-queen/${queenAuth.id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setMyProduct(data.data);
        setLoading(false);
      });
  }, [queenAuth]);

  // Delete a product function
  const handleDeleteProduct = (id) => {
    fetch(`${url}/api/admin/product/delete/${id}`, {
      method: 'DELETE',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const restProducts = myProduct.filter((product) => product.id !== id);
          setMyProduct(restProducts);
          alert('Product Deleted Successfully');
        }
      });
  };

  return (
    <div className='position-relative'>
      <div className='my-products-wrapper pb-5'>
        <div>
          {loading ? (
            <div className='text-center mt-5'>
              <Spinner animation='border' style={{ color: '#e9313a' }} />
            </div>
          ) : (
            <div>
              {myProduct.length ? (
                <>
                  {myProduct.map((product) => (
                    <ShowMyProduct
                      key={product.id}
                      handleDeleteProduct={handleDeleteProduct}
                      handleEditProductModal={handleProductEditShow}
                      product={product}
                      setEditProduct={setEditProduct}
                    />
                  ))}
                </>
              ) : (
                <div
                  onClick={handleProductAddShow}
                  className='text-center pointer opacity-50'
                >
                  <RiImageAddLine className='add-product ' />
                  <p className='fw-bold fs-2'>Add Product</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <AddProductModal
        handleClose={handleProductAddClose}
        show={showProductAdd}
        myProduct={myProduct}
        setMyProduct={setMyProduct}
      />

      <EditProductModal
        handleClose={handleProductEditClose}
        show={showProductEdit}
        editProduct={editProduct}
      />
      <div>
        <div className='d-flex align-items-center justify-content-around my-products-menu pt-1'>
          <Link to='/account' className='text-center text-decoration-none'>
            <RiHome4Line className='my-products-logo' />
            <br />
            <small className='fw-bold text-white'>Home</small>
          </Link>
          <span onClick={handleProductAddShow} className='text-center pointer'>
            <RiImageAddLine className='my-products-logo' />
            <br />
            <small className='fw-bold text-white'>Add Product</small>
          </span>

          <a
            href={`https://onthe-way.com/queen/${queenAuth.id}`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-center  text-decoration-none'
          >
            <RiUser6Line className='my-products-logo' />
            <br />
            <small className='fw-bold text-white'>My Store</small>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QueenProducts;
