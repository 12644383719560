import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { districts } from '../../fakeData/districts';
import { url } from '../../Helpers/Constant';

const ProfileEditModal = ({ show, handleClose }) => {
  const { queenAuth, setQueenAuth } = useQueenAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [divisionID, setDivisionID] = useState(queenAuth.division);
  const { name, email, post_code, id, address, division, city } = queenAuth;

  const handleProfileEdit = (data) => {
    setIsLoading(true);
    fetch(`${url}/api/admin/queen/update/info/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((tada) => {
        if (tada.success) {
          alert(tada.message);
          setQueenAuth({ ...queenAuth, ...data });
          setIsLoading(false);
          handleClose();
        } else {
          alert(tada.message);
          setIsLoading(false);
        }
      });
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Edit Your Profile</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleProfileEdit)}>
          <div>
            <div>
              <p className='fw-bold'>Name:</p>
              <input
                className='sign-up-input w-100'
                {...register('name')}
                defaultValue={name}
                maxLength='250'
                type='text'
                placeholder='Enter your name'
              />
            </div>
            <div className='mt-3'>
              <p className='fw-bold'>Your Email:</p>
              <input
                {...register('email')}
                defaultValue={email}
                type='email'
                className='sign-up-input w-100'
                placeholder='Enter your email'
              />
            </div>
            <div className='my-2'>
              <div>
                <div>
                  <div>
                    <label className='fw-bold text-start pb-1'>
                      Select Your Division
                    </label>
                    <select
                      onClick={(e) => setDivisionID(e.target.value)}
                      className='sign-up-input w-100'
                      type='text'
                      defaultValue={division}
                      {...register('division')}
                    >
                      <option value=''>Select Division</option>
                      <option value='Barisal'>Barisal</option>
                      <option value='Chittagong'>Chittagong</option>
                      <option value='Dhaka'>Dhaka</option>
                      <option value='Khulna'>Khulna</option>
                      <option value='Mymensingh'>Mymensingh</option>
                      <option value='Rajshahi'>Rajshahi</option>
                      <option value='Rangpur'>Rangpur</option>
                      <option value='Rajshahi'>Sylhet</option>
                    </select>
                  </div>
                  <div className='mt-2'>
                    <label className='fw-bold text-start pb-1'>
                      Select Your city
                    </label>

                    <select
                      className='sign-up-input w-100'
                      type='text'
                      {...register('city')}
                      defaultValue={city}
                    >
                      {districts.map(
                        (dis) =>
                          divisionID === dis.division_name && (
                            <option value={dis.name} key={dis.id}>
                              {dis.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
                <div className='my-2'>
                  <label className='mb-1 fw-bold'>Enter Your Address</label>
                  <br />
                  <input
                    placeholder='Enter Your Address'
                    type='text'
                    defaultValue={address}
                    className='sign-up-input w-100'
                    {...register('address')}
                  />
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <p className='fw-bold'>Post code:</p>
              <input
                {...register('post_code')}
                defaultValue={post_code}
                type='number'
                className='w-100 sign-up-input'
                placeholder='Enter your post code'
              />
            </div>
          </div>
          <div className='mt-4 text-center'>
            {isLoading ? (
              <div className='text-center mt-5'>
                <Spinner animation='border' style={{ color: '#e9313a' }} />
              </div>
            ) : (
              <input
                className='next-btn '
                type='submit'
                value='Update Profile'
              />
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileEditModal;
