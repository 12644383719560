import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { url } from '../../Helpers/Constant';

const ShowMyProduct = ({
  product,
  handleDeleteProduct,
  handleEditProductModal,
  setEditProduct,
}) => {
  const {
    product_picture_1,
    product_picture_2,
    product_name,
    id,
    price,
    status,
    category,
    short_desc,
  } = product;

  return (
    <div className='mb-3'>
      <div>
        <Row className='align-items-center'>
          <Col xs={9}>
            <div className='d-flex gap-4 align-items-center'>
              <div>
                <img
                  className='py-2'
                  width={100}
                  height={150}
                  style={{ objectFit: 'contain' }}
                  src={`${url}/get/image/products/${product_picture_1}`}
                  alt=''
                />
              </div>
              {product_picture_2 && (
                <div>
                  <img
                    className='py-2'
                    width={100}
                    src={`${url}/get/image/products/${product_picture_2}`}
                    alt=''
                  />
                </div>
              )}
            </div>
          </Col>
          <Col xs={3}>
            <div>
              <div className=''>
                <Button
                  onClick={() => {
                    handleEditProductModal();
                    setEditProduct(product);
                  }}
                  variant='outline-warning px-4'
                  size='sm'
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    const confirm = window.confirm('Are You Sure?');
                    if (confirm) {
                      handleDeleteProduct(id);
                    }
                  }}
                  variant='outline-danger mt-2 px-3'
                  size='sm'
                >
                  Delete
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='px-3 py-1 product-details-my-products'>
        <div className='d-flex gap-2 align-items-center justify-content-between mb-2'>
          <h4>{product_name} </h4>
          <div className='d-flex flex-column align-items-center'>
            <small
              className={
                status === 'Rejected'
                  ? 'text-danger '
                  : status === 'Pending'
                  ? 'text-primary'
                  : 'text-success'
              }
            >
              ({status} Product)
            </small>
            {status === 'Approved' && (
              <a
                href={`https://onthe-way.com/product/${id}`}
                rel='noopener noreferrer'
              >
                View Product
              </a>

              // <Link to={`https://onthe-way.com/product/${id}`}>
              //   View Product
              // </Link>
            )}
          </div>
        </div>
        <div className='d-flex align-items-center gap-4 '>
          <p>
            <span className='fw-bold'>Price:</span> &#2547;{price}
          </p>
          <p>
            <span className='fw-bold'>Product Category: </span>
            {category}
          </p>
        </div>
        <p>
          <span className='fw-bold'>Details:</span> {short_desc}
        </p>
      </div>
    </div>
  );
};

export default ShowMyProduct;
