import React, { useState, useEffect } from 'react';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';
import { Col, Row, Spinner } from 'react-bootstrap';
const QueenOrders = () => {
  const { queenAuth } = useQueenAuth();
  const [myOrders, setMyOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${url}/andro/api/orders/get/all/by-status/queen/${queenAuth.id}/approved`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setMyOrders(data.data);
          setLoading(false);
        }
      });
  }, [queenAuth.id]);

  return (
    <div className='my-5 text-center'>
      {loading ? (
        <div>
          <div className='text-center mt-5'>
            <Spinner animation='border' style={{ color: '#e9313a' }} />
          </div>
        </div>
      ) : (
        <div>
          {myOrders.length ? (
            <div>
              {myOrders.map((sOrder) => {
                const {
                  price,
                  product_name,
                  order_date,
                  product_picture_1,
                  product_picture_2,
                  short_desc,
                  quantity,
                  order_id,
                } = sOrder;
                return (
                  <div className='mb-3' key={order_id}>
                    <div className='mb-2'>
                      <Row className='align-items-center'>
                        <Col xs={12}>
                          <div className='d-flex align-items-center  justify-content-evenly '>
                            <div>
                              <img
                                width={180}
                                height={180}
                                style={{ objectFit: 'contain' }}
                                src={`${url}/get/image/products/${product_picture_1}`}
                                alt=''
                              />
                            </div>
                            {product_picture_2 && (
                              <div>
                                <img
                                  width={180}
                                  height={180}
                                  src={`${url}/get/image/products/${product_picture_2}`}
                                  alt=''
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className='px-3 py-2 product-details-my-products text-start'>
                      <p>
                        <span className='fw-bold'>Product Name:</span>{' '}
                        {product_name}
                      </p>
                      <p>
                        <span className='fw-bold'>Price:</span> &#2547;{price}
                      </p>
                      <p>
                        <span className='fw-bold'>Quantity:</span> {quantity}
                      </p>
                      <p>
                        <span className='fw-bold'>Order Date:</span>{' '}
                        {order_date.slice(0, 10)}
                      </p>
                      <p>
                        <span className='fw-bold'>Description:</span>{' '}
                        {short_desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <p className='fs-3 font-weight-bold'>You have no order yet!</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QueenOrders;
