import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useQueenAuth } from '../../Context/QueenAuthContext';
import { url } from '../../Helpers/Constant';

const TranieeModal = ({ show, handleClose }) => {
  const { queenAuth, setQueenAuth } = useQueenAuth();

  const [password, setPassword] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handelSubmit = () => {
    setIsLoading(true);
    fetch(`${url}/otw-training/api/auth/trainee/register/by/queen`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: queenAuth.phone, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          sessionStorage.setItem(
            '__q_o',
            JSON.stringify({ ...queenAuth, trainee: 1 })
          );
          setQueenAuth({ ...queenAuth, trainee: 1 });

          handleClose();
          setIsLoading(false);
        } else {
          setError(data.message);
          setTimeout(() => {
            setError('');
          }, 5000);
        }
        setIsLoading(false);
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Be Traniee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-2 text-center'>
            <label className='fw-bold'>Enter Queen Account Password</label>
            <br />
            <input
              type='password'
              placeholder='Enter Password'
              onChange={(e) => setPassword(e.target.value)}
              className='sign-up-input mt-2'
            />

            {error && (
              <div className='pt-3'>
                <p className='fw-bold text-danger'>{error}</p>
              </div>
            )}

            <div className='mt-2'>
              {loading ? (
                <div className='text-center mt-5'>
                  <Spinner animation='border' style={{ color: '#e9313a' }} />
                </div>
              ) : (
                <button
                  type='submit'
                  value='Submit'
                  style={{
                    border: '1px solid gray',
                    backgroundColor: 'gray',
                    color: 'white',
                    borderRadius: '5px',
                  }}
                  onClick={handelSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TranieeModal;
