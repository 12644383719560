import React, { useState } from 'react';
import { url } from '../../Helpers/Constant';
// import QueenTrainingRegisterModal from './QueenTrainingRegisterModal';
import { FiChevronDown } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';
import moment from 'moment/moment';

const ShowCourse = ({ course }) => {
  const [showDetails, setShowDetails] = useState(false);
  // Modal
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const {
    details,
    trainer_name,
    thumbnail,
    title,
    training_date,
    inst_name,
    // trainer_id,
    // status,
    time,
  } = course;

  return (
    <div className='mt-3 training-card'>
      <div>
        <div className='course-card text-center border-bottom'>
          <img
            loading='lazy'
            className='training-cover-img '
            src={`${url}/get/image/training_thumbnail/${thumbnail}`}
            alt=''
          />
        </div>
        <div
          className='d-flex align-items-center justify-content-between mx-3 my-2 pointer'
          onClick={() => setShowDetails((prv) => !prv)}
        >
          <h5>Training details</h5>
          <div>
            {showDetails ? (
              <FiChevronDown size={25} />
            ) : (
              <FiChevronUp size={25} />
            )}
          </div>
        </div>
        {showDetails && (
          <div className='mx-3 mt-2'>
            <ul className='training-details'>
              <li>
                <span>Training title</span> : <p>{title}</p>
              </li>
              <li>
                <span>Trainer name</span> : <p>{trainer_name}</p>
              </li>
              <li>
                <span>Institute name</span> : <p>{inst_name}</p>
              </li>
              <li>
                <span>Date</span> :
                <p>{moment(training_date).format('Do MMM  YY')} </p>
              </li>
              <li>
                <span>Time</span> :{' '}
                <p>{moment(time, 'h:mm a').format('h:mm a')}</p>
              </li>
              <li>
                <span>Details</span> : <p>{details} </p>
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* 
      <div>
        {status === 'Upcoming' && (
          <QueenTrainingRegisterModal
            show={show}
            handleClose={handleClose}
            id={trainer_id}
          />
        )}
      </div> */}
    </div>
  );
};

export default ShowCourse;
